/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import {createVuetify} from 'vuetify'
import {md3} from 'vuetify/blueprints'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  blueprint: md3,
  defaults:{
    VCard:{
      elevation:0,
      border:true,
    },
    VFieldInput:{
      elevation:0,
      border: true,
    }
  },
  theme: {
    defaultTheme: 'v2',
    themes: {
      v2: {
        colors:{
          primary: '#009688',
          secondary: '#00695C',
        }
      },
      v2dark: {
        dark: true,
        colors:{
          primary: '#009688',
          secondary: '#00695C',
        }
      },
      light: {
        colors: {
          primary: '#1867C0',
          secondary: '#5CBBF6',
        },
      },
    },
  },
})
