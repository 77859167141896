import {RouteName} from "@/keys";

export default [
    {
        path: '/',
        component: () => import('@/layouts/default/default-layout.vue'),
        children: [
            {
                ...RouteName.website.home,
                component: () => import(/* webpackChunkName: "web-h" */'@/views/website/home-view.vue'),
            },
            {
                ...RouteName.website.about,
                component: () => import(/* webpackChunkName: "web-a" */'@/views/website/about-view.vue'),
            },
            {
                ...RouteName.website.brand.list,
                component: () => import(/* webpackChunkName: "web-b-l" */'@/views/website/brands/brand-list-view.vue'),
            },
            {
                ...RouteName.website.brand.show,
                component: () => import(/* webpackChunkName: "web-b-s" */'@/views/website/brands/brand-detail-view.vue'),
            },
            {
                ...RouteName.website.merchant.show,
                component: () => import(/* webpackChunkName: "web-m-s" */'@/views/website/merchants/merchant-detail-view.vue'),
            },
            {
                ...RouteName.website.merchant.list,
                component: () => import(/* webpackChunkName: "web-m-l" */'@/views/website/merchants/merchant-list-view.vue'),
            },
            {
                ...RouteName.website.product.show,
                component: () => import(/* webpackChunkName: "web-p-s" */'@/views/website/products/product-detail-view.vue'),
            },
            {
                ...RouteName.website.product.list,
                component: () => import(/* webpackChunkName: "web-p-s" */'@/views/website/products/product-list-view.vue'),
            },
            {
                ...RouteName.website.cheapest.merchant,
                component: () => import(/* webpackChunkName: "web-c-h" */'@/views/website/cheapest/merchant-cheapest-view.vue'),
            },
            {
                ...RouteName.website.cheapest.brand,
                component: () => import(/* webpackChunkName: "web-c-b" */'@/views/website/merchants/merchant-list-view.vue'),
            },
            {
                ...RouteName.website.cheapest.product,
                component: () => import(/* webpackChunkName: "web-c-p" */'@/views/website/merchants/merchant-list-view.vue'),
            },
            {
                ...RouteName.website.legal.privacy,
                component: () => import(/* webpackChunkName: "web-l-p" */'@/views/website/legal/privacy-view.vue'),
            },
            {
                ...RouteName.website.legal.terms,
                component: () => import(/* webpackChunkName: "web-l-t" */'@/views/website/legal/terms-view.vue'),
            },


            {
                ...RouteName.website.notFound,
                component: () => import(/* webpackChunkName: "web-n" */'@/views/website/not-found-view.vue'),
            },
            {
                path: '/:pathMatch(.*)*',
                redirect: {
                    name: RouteName.website.notFound.name,
                }
            }
        ],
    },
]
