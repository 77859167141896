<template>
  <router-view/>
</template>

<script lang="ts" setup>
import moment from "moment";
import {AppName, Features} from "@/keys";
import {useHead} from "@vueuse/head";
import {useRoute} from "vue-router";
import {onMounted} from "vue";
import {useApplicationStore} from "@/store/application";

const route = useRoute();
const applicationStore = useApplicationStore();

//ensure we always can fetch new data:
const localstorageWatcher = () => {
  const initTime = localStorage.getItem('db-init-time') ?? null;

  if (!initTime) {
    //Wait for next time to do this...
    localStorage.setItem('db-init-time', moment().utc().toISOString(true));
    return;
  }

  const lastUpdateTime = moment(initTime);

  if (moment().subtract(1, 'hour').isAfter(lastUpdateTime)) {
    localStorage.clear();
    document.location.reload();
  }
}

if (Features.autoRefresh) {
  localstorageWatcher();
  setInterval(localstorageWatcher, 15000);
}

onMounted(() => {
  //Ensure we do not display BS to google...
  if (navigator.userAgent.toLowerCase().includes("googlebot")) {
    applicationStore.migrationNoticeWasDismissed = true;
  }
});

useHead({
  title: '',
  titleTemplate(title) {
    return title
      ? `${title} | ${AppName}`
      : AppName
  },
  link: () => [
    {
      rel: 'canonical', href: () => `${location.protocol}//${location.host}${route.fullPath}`
    }
  ],
});


</script>
