import {defineStore} from "pinia";
import {ref} from "vue";

export const useNavigationStore = defineStore('navigation', () => {
    const displayMainNavigation = ref(false);

    return {
        displayMainNavigation,
    }
})
