/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import {loadFonts} from '@/plugins/webfontloader'
import vuetify from '@/plugins/vuetify'
import pinia from '@/store'
import {firebaseApp} from "@/plugins/firebase";
import {
    VueFire,
    VueFireAppCheck,
    // VueFireAuth,
} from "vuefire";
import {ReCaptchaV3Provider} from 'firebase/app-check'
import router from '@/router'
import i18n from '@/plugins/i18n'
import VueGtag from "vue-gtag-next";
import {
    Features,
    APP_CHECK_TOKEN,
    GOOGLE_ANALYTICS_MEASUREMENT_ID,
} from "@/keys";

import {createHead} from "@vueuse/head"

// Types
import type {App, Plugin} from 'vue'
//@ts-ignore
import bugsnag from "@/plugins/bugsnag";

export function registerPlugins(app: App) {
    loadFonts();
    if (Features.bugsnag && bugsnag !== undefined) {
        app.use(bugsnag as Plugin)
    }

    app.use(vuetify)
        .use(VueFire, {
            firebaseApp,
            modules: [
                // VueFireAuth(),
                VueFireAppCheck({
                    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
                    isTokenAutoRefreshEnabled: true,
                    debug: Features.debug ? APP_CHECK_TOKEN : undefined,
                }),
            ],
        })
        .use(router)
        .use(i18n)
        .use(pinia)
        .use(createHead())
        .use(VueGtag, {
            // disableScriptLoader: true,
            property: {id: GOOGLE_ANALYTICS_MEASUREMENT_ID},
            isEnabled: Features.analytics,
        });
}
