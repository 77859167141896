import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, {BugsnagPluginVueResult} from '@bugsnag/plugin-vue'
//@ts-ignore
import {BUGSNAG_API_KEY, Features} from "@/keys";

let plugin: BugsnagPluginVueResult | undefined = undefined;
if (Features.bugsnag) {
    Bugsnag.start({
        apiKey: BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
    });

    plugin = Bugsnag.getPlugin('vue');
}

export default plugin;
