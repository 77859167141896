import {createI18n} from "vue-i18n";
import NB_NO from '@/lang/manual_nb_no.json';
// import {computed} from "vue";
import {AppName} from "@/keys";

// type MessageSchema = typeof NB_NO;

const applyDefaultTemplateVariables = (messages: Record<string, any>) => {
    return {
        ...messages,
        APP_NAME: AppName,
    }
}

const messages = {
    ['no']: applyDefaultTemplateVariables(NB_NO),
};

const numberFormats = () => {
    const formats: Record<string, any> = {};
    for (const locale in messages) {
        formats[locale] = {
            currency: {
                style: 'currency',
                currency: 'NOK',
            },
        };
    }
    return formats;
};


export default createI18n({
    legacy: false,
    locale: 'no',
    fallbackLocale: 'no',
    numberFormats: numberFormats(),
    messages,
});
