import {initializeApp} from "firebase/app";
// import {getAnalytics} from "firebase/analytics";
// import {GOOGLE_ANALYTICS_MEASUREMENT_ID} from "@/keys";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export const firebaseConfig = {
  apiKey: "AIzaSyAIj4P37duUkelcpVpJblmqSxa-JeIvxyY",
  authDomain: "snuspris-no.firebaseapp.com",
  projectId: "snuspris-no",
  storageBucket: "snuspris-no.appspot.com",
  messagingSenderId: "120021610077",
  appId: "1:120021610077:web:004995a5d89a038961a7b4",
  measurementId: undefined
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// export const firebaseAnalytics = getAnalytics(firebaseApp);
export default firebaseApp;
