import {Product} from "@/lib/keys";
import {version as appVersion} from './../package.json'

export const AppName = 'Snuspris.no';
export const APP_VERSION = appVersion;
export const BUGSNAG_API_KEY
  = import.meta.env.VITE_BUGSNAG_API_TOKEN
  ?? '488ef84f9c25aa81b7b93bd59b67cf20'
export const GOOGLE_RECAPTCHA_V3_SITE_KEY
  = '6LefmqMnAAAAAK2ZA7b0i73SyMUcTkXAkbnhx1kC';
export const APP_CHECK_TOKEN
  = import.meta.env.PROD
  ? GOOGLE_RECAPTCHA_V3_SITE_KEY
  : 'EAEE2C82-B168-4D38-AC0C-5850B3AAC3A8';
export const GOOGLE_ANALYTICS_MEASUREMENT_ID
  = 'G-9EHD9GDFM6';

export const LIMITATIONS = {
  MAX_PRICE:300,
}

interface FeatureSet {
  bugsnag: boolean;
  analytics: boolean;
  autoRefresh: boolean;
  debug: boolean;

  [key: string]: boolean;
}

export const Features: FeatureSet = {
  bugsnag: import.meta.env.PROD,
  analytics: import.meta.env.PROD,
  autoRefresh: true,
  debug: !import.meta.env.PROD,
  isLocal: location.hostname === 'localhost',
}

if (Features.debug) {
  console.log('Enabled Features:');
  Object.keys(Features).forEach(feature => {
    console.log(`${feature.toUpperCase()}: `, Features[feature])
  });
}

export interface RouteItem {
  name: string;
  path: string;
  alias?: string[],
  meta?: any;
}

const routeItem = (name: string, path: string, additional: Record<string, any> = {}): RouteItem => ({
  name,
  path, ...additional
});

const adminRouteItem = (name: string, path: string, additional: Record<string, any> = {}): RouteItem => ({
  name,
  path,
  meta: {requiresAuth: true, adminOnly: true},
  ...additional
})

export type RouteMap = typeof RouteName;

export const RouteName = {
  auth: {
    login: routeItem('auth.login', '/login'),
    logout: routeItem('auth.logout', '/logout'),
    signup: routeItem('auth.signup', '/signup'),
  },
  website: {
    home: routeItem('web.home', '/'),
    about: routeItem('web.about', '/about'),
    contact: routeItem('web.contact', '/contact'),
    notFound: routeItem('web.not-found', '/not-found'),

    //Domain specific
    brand: {
      list: routeItem('web.brands.list', '/snus'),
      show: routeItem('web.brands.show', '/snus/:brand'),
    },
    merchant: {
      list: routeItem('web.merchant.list', '/merchants'),
      show: routeItem('web.merchant.show', '/merchants/:merchant'),
    },
    product: {
      show: routeItem('web.products.show', '/snus/:brand/:product'),
      list: routeItem('web.products.list', '/products'),
    },
    price: {
      redirectToPrice: routeItem('web.price.redirect', '/snus/:brand/:product/:merchant/redirect')
    },
    cheapest: {
      merchant: routeItem('web.cheapest.merchants.show', '/cheapest/merchants'),
      product: routeItem('web.cheapest.products.show', '/cheapest/products'),
      brand: routeItem('web.cheapest.brands.show', '/cheapest/brands'),
    },

    legal: {
      privacy: routeItem('web.legal.privacy', '/privacy'),
      terms: routeItem('web.legal.terms', '/terms'),
    }
  },
  admin: {
    home: adminRouteItem('admin.home', '/admin'),
    posts: {
      list: adminRouteItem('admin.posts.list', '/admin/posts'),
      show: adminRouteItem('admin.posts.show', '/admin/posts/:post'),
      create: adminRouteItem('admin.posts.create', '/admin/posts/create'),
    },
    products: {
      list: adminRouteItem('admin.products.list', '/admin/products'),
      show: adminRouteItem('admin.products.show', '/admin/products/:product'),
      create: adminRouteItem('admin.products.create', '/admin/products/create'),
    },
    merchants: {
      list: adminRouteItem('admin.merchants.list', '/admin/merchants'),
    }
  }
}

const navigationItem = (titleKey: string, routeName: string, children: object[] = [], additional = {}) => ({
  titleKey,
  routeName,
  children,
  ...additional
})

export const MainNavigation = [
  navigationItem(
    'navigation.home',
    RouteName.website.home.name,
  ),
  navigationItem(
    'navigation.snus',
    RouteName.website.brand.list.name,
    // [
    //     navigationItem(
    //         'navigation.brands',
    //         RouteName.website.brand.list.name,
    //     ),
    //     navigationItem(
    //         'navigation.merchants',
    //         RouteName.website.merchant.list.name,
    //     ),
    //     navigationItem(
    //         'navigation.products',
    //         RouteName.website.product.list.name,
    //     ),
    // ]
  ),
  navigationItem(
    'navigation.cheapest.title',
    RouteName.website.cheapest.merchant.name,
  ),
  // navigationItem(
  //     'navigation.cheapest.title',
  //     RouteName.website.merchant.list.name,
  //     [
  //         navigationItem(
  //             'navigation.cheapest.products',
  //             RouteName.website.cheapest.product.name,
  //         ),
  //         navigationItem(
  //             'navigation.cheapest.brands',
  //             RouteName.website.cheapest.brand.name,
  //         ),
  //     ]
  // ),
  navigationItem(
    'navigation.site',
    RouteName.website.about.name,
    // [
    //     navigationItem(
    //         'navigation.about',
    //         RouteName.website.about.name,
    //     ),
    //     navigationItem(
    //         'navigation.correction',
    //         RouteName.website.home.name,
    //     ),
    //     navigationItem(
    //         'navigation.contact',
    //         RouteName.website.home.name,
    //     ),
    // ]
  ),
]


export const AppBar = {
  DOCUMENT_TOP: 0,
  HEIGHT_TOP: 70,
  HEIGHT_SCROLLING: 55,

  TEXT_SIZE: 20,
  TEXT_SIZE_SCROLLING: 17,
};


export const routeToProduct = (product: Product) => ({
  ...RouteName.website.product.show,
  params: {product: product.slug}
})

