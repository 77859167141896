// Composables
import WebsiteRoutes from '@/router/website';
import {createRouter, createWebHistory} from 'vue-router'
import {getCurrentUser} from "vuefire";
import {useNavigationStore} from "@/store/navigation";
import { trackRouter } from "vue-gtag-next";


const routes = [
    ...WebsiteRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth'
                }
            }
            return { top: 0 }
        }
    },
    routes,
});


router.beforeEach(async (to) => {
    const nav = useNavigationStore();

    if(nav.displayMainNavigation){
        nav.displayMainNavigation = false;
    }

    // routes with `meta: { requiresAuth: true }` will check for
    // the users, others won't
    if (Object.prototype.hasOwnProperty.call(to.meta, 'requiresAuth') && to.meta.requiresAuth) {
        const currentUser = await getCurrentUser()
        // if the user is not logged in, redirect to the login page
        if (!currentUser) {
            return {
                path: '/login',
                query: {
                    // we keep the current path in the query so we can
                    // redirect to it after login with
                    // `router.push(route.query.redirect || '/')`
                    redirect: to.fullPath,
                },
            }
        }
    }
});


trackRouter(router, {
  template(to){
    return {
      page_title: to.path,
      page_path: to.path,
      page_location: to.fullPath
    }
  }
})

export default router
